<template>
  <div class="serviceHall">
    <!-- <index></index> -->
    <div class="searchContent">
      <div class="itemContent">
        <div class="searchItem">
          <div class="s-title">分类：</div>
          <span
            :class="formData.coreId == item.id ? 'checkedItem' : ''"
            v-for="item in tabList1"
            :key="item.id"
            @click="getServiceType(item)"
            >{{ item.name }}</span
          >
        </div>
        <div v-show="!isClose">
          <div v-show="isShow" class="searchItem">
            <div class="s-title">服务类型：</div>
            <span
              :class="formData.serviceTypeId === item.id ? 'checkedItem' : ''"
              v-for="item in tabList5"
              :key="item.id"
              @click="changeTypeID(item)"
              >{{ item.name }}</span
            >
          </div>
          <!-- <div class="searchItem">
            <div class="s-title">距离：</div>
            <span
              :class="lsitStr2 == item ? 'checkedItem' : ''"
              v-for="item in tabList2"
              :key="item"
              @click="lsitStr2 = item"
              >{{ item }}</span
            >
          </div> -->
          <div class="searchItem">
            <div class="s-title">发布时间：</div>
            <span
              :class="formData.dayNum == item.value ? 'checkedItem' : ''"
              v-for="item in tabList3"
              :key="item.value"
              @click="changeDay(item)"
              >{{ item.label }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="lineContent">
      <div class="lineText" @click="isClose = !isClose">
        {{ isClose ? "展开" : "折叠" }}
      </div>
    </div>
    <div class="tabSearch">
      <div class="tabSearchList">
        <span
          :class="formData.orderType === item.orderType ? 'checked' : ''"
          v-for="(item, index) in tabList4"
          :key="item.name"
          @click="changeSort(item, index)"
          >{{
            item.name +
            (item.orderType === formData.orderType
              ? item.ascDesType === 1
                ? "⬆"
                : "⬇"
              : "")
          }}
        </span>
      </div>
      <div>
        <el-input v-model="formData.seachData"></el-input>
        <el-button @click="searchFnc" style="line-height: 10px">搜索</el-button>
      </div>
    </div>
    <div class="picContent">
      <div class="picBox">
        <div
          v-for="(item, index) in currentData"
          :key="index"
          class="boxContent"
          @click="openDetail(index)"
        >
          <div style="width: 324px; height: 252px; overflow: hidden">
            <img :src="item.imgUrl" />
          </div>
          <div class="textContent">
            <div
              class="textContent1"
              style="display: flex; justify-content: space-between"
            >
              <span>
                <span
                  style="font-size: 26px; color: #17a763"
                  v-if="item.budgetType === 1"
                  >面议</span
                >
                <span
                  v-if="item.budgetType !== 1"
                  style="font-size: 26px; color: #17a763"
                  >{{ item.price }}</span
                >
                <span v-if="item.budgetType !== 1">{{ item.unitName }}</span>
              </span>
              <span>{{
                (item.startTime || "") + "-" + (item.endTime || "")
              }}</span>
            </div>
            <div class="textContent2" style="font-size: 18px;color#333">
              {{ item.title }}
            </div>
            <div class="textContent1">
              <span style="width: 70px">服务组织&nbsp;&nbsp;&nbsp;</span>
              <div style="width: 220px" class="textContent2">
                {{ item.orgName }}
              </div>
            </div>
            <div class="textContent1">
              <span style="width: 70px">服务区域&nbsp;&nbsp;&nbsp;</span>
              <div style="width: 200px" class="textContent2">
                {{ optArea(item) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pageContent">
        <el-pagination
          v-show="total > 0"
          @current-change="changePage"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="formData.pageSize"
        >
          <template slot="prev">上一页</template>
          <template slot="next">下一页</template>
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { serviceHall, getServiceType } from "../../api/serviechall";
export default {
  data() {
    return {
      formData: {
        seachData: "",
        ascDesType: 1,
        cityCode: "",
        coreId: "",
        countyCode: "",
        dayNum: "",
        orderType: "",
        pageNum: 1,
        pageSize: 12,
        params: {},
        provinceCode: "",
        searchValue: "",
        coreId: "",
        townCode: "",
        varietyId: "",
        villageCode: "",
        isShow: false,
        serviceTypeId: "",
      },
      str: "服务大厅",
      array: ["首页", "新闻资讯", "服务大厅", "需求大厅", "组织名录"],
      tabList1: [],
      lsitStr2: "不限",
      tabList2: ["不限", "0km-5km", "5km-10km", "10km-30km", "30km以上"],
      tabList3: [
        { label: "不限", value: "" },
        { label: "近1天", value: 1 },
        { label: "近7天", value: 7 },
        { label: "近一个月", value: 30 },
      ],
      tabList4: [
        { name: "综合排序", orderType: 9, ascDesType: 1 },
        // { name: "距离", orderType: 4, ascDesType: 1 },
        { name: "价格", orderType: 3, ascDesType: 1 },
        { name: "浏览数", orderType: 2, ascDesType: 1 },
        { name: "发布时间", orderType: 1, ascDesType: 1 },
      ],
      tabList5: [],
      isClose: true,
      picList: [],
      currentData: [],
      total: 0,
      isShow: false,
    };
  },
  methods: {
    optArea(item) {
      const { province, city, county, town, village } = item;
      return (
        (province || "") +
        (city || "") +
        (county || "") +
        (town || "") +
        (village || "")
      );
    },
    changePage(page) {
      this.formData.pageNum = page;
      this.searchFnc();
    },
    openDetail(index) {
      let query = { id: this.picList[index].id };
      let routeUrl = this.$router.resolve({
        path: "/portal/serviceHallDetail",
        query,
      });
      window.open(routeUrl.href, "_blank");
    },
    searchFnc() {
      serviceHall(this.formData).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          const { ccoreBusiness, serviceReleaseList } = data;
          this.tabList1 = [{ name: "全部", id: "" }, ...ccoreBusiness];
          this.picList = serviceReleaseList.map((item) => {
            if (item.imgUrl) {
              item.imgUrl = item.imgUrl.split(",")[0];
            }
            return item;
          });
          this.currentData = serviceReleaseList;
          this.total = data.total;
        }
      });
    },
    changeSort(item, index) {
      if (this.formData.orderType === this.tabList4[index].orderType) {
        this.formData.ascDesType = (this.formData.ascDesType + 1) % 2;
        this.tabList4[index].ascDesType =
          (this.tabList4[index].ascDesType + 1) % 2;
      } else {
        this.formData.orderType = item.orderType;
        this.tabList4.forEach((item) => {
          item.ascDesType = 1;
        });
        this.formData.ascDesType = 1;
      }
      this.formData.orderType = this.tabList4[index].orderType;
      this.searchFnc();
    },
    getServiceType(item) {
      this.formData.coreId = item.id;
      if (item.id !== "") {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.searchFnc();
        return;
      }
      getServiceType({ coreId: item.id }).then((res) => {
        const { rows, code } = res;
        if (code === 200) {
          this.tabList5 = [{ name: "全部", id: "" }, ...rows];
          this.formData.serviceTypeId = "";
          this.searchFnc();
        }
      });
    },
    changeTypeID(item) {
      this.formData.serviceTypeId = item.id;
      this.searchFnc();
    },
    changeDay(item) {
      this.formData.dayNum = item.value;
      this.searchFnc();
    },
  },
  watch: {
    watchData: {
      deep: true,
      handler: function () {
        if (this.$store.state.formData.id == 2) {
          this.formData.seachData = this.$store.state.formData.keyWord;
          this.searchFnc();
        }
      },
      immediate: true,
    },
  },
  computed: {
    watchData() {
      return this.$store.state.formData;
    },
  },
  created() {
    this.searchFnc();
  },
};
</script>
<style lang="scss" scoped>
.serviceHall {
  width: 100%;
  .searchContent {
    width: 1396px;
    margin: 0 auto;
    .itemContent {
      margin: 20px 0px 22px 0px;
      .searchItem {
        display: flex;
        margin-bottom: 14px;
        .s-title {
          width: 78px;
          text-align: right;
          font-size: 14px;
          color: #333333;
          font-weight: bold;
          display: block;
          line-height: 27px;
        }
        span {
          font-size: 14px;
          color: #555555;
          line-height: 27px;
          text-align: center;
          overflow: hidden;
          padding: 0 5px;
          min-width: 99px;
        }
        span:hover {
          background-color: #17a763;
          border-radius: 10px;
        }
        .checkedItem {
          background-color: #17a763;
          border-radius: 10px;
          color: #fff;
        }
      }
    }
  }
  .lineContent {
    width: 1396px;
    margin: 0 auto;
    position: relative;
    height: 24px;
    border-top: 1px solid #ddd;
    margin-bottom: 5px;
    .lineText {
      cursor: pointer;
      position: absolute;
      left: 50%;
      margin-left: -57px;
      display: block;
      width: 114px;
      height: 24px;
      top: -2px;
      background-color: #fbfbfb;
      text-align: center;
      border: 1px solid #dcdcdc;
      border-top-color: #fff;
      line-height: 24px;
    }
  }
  .tabSearch {
    padding-right: 19px;
    box-sizing: border-box;
    width: 1396px;
    margin: 25px auto 0;
    background-color: #f5f5f5;
    height: 44px;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/ .el-input__inner {
      height: 30px;
    }
    .tabSearchList {
      display: flex;
      align-items: center;
      height: 100%;
    }
    span {
      display: block;
      height: 100%;
      border-right: 1px solid #dddddd;
      font-size: 14px;
      color: #333333;
      line-height: 44px;
      text-align: center;
      padding: 0 15px;
      cursor: pointer;
    }
    .checked {
      background-color: #17a763;
      color: #fff;
    }
    .el-input {
      width: 297px;
      margin: 0 10px;
    }
    /deep/.el-input__inner {
      width: 299px;
      height: 27px;
      background-color: #fff;
      border: 1px solid #dddddd;
      outline: none;
    }
    /deep/ .el-button {
      width: 56px;
      height: 28px;
      background-color: #17a763;
      color: #fff;
    }
  }
  .picContent {
    width: 1396px;
    margin: 18px auto;
    padding: 20px 20px 20px 20px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #dddddd;
    .picBox {
      display: flex;
      flex-wrap: wrap;
      .boxContent {
        cursor: pointer;
        width: 324px;
        box-shadow: 1px 4px 6px;
        margin-right: 19px;
        margin-bottom: 19px;
        overflow: hidden;
      }
      .boxContent:nth-child(4n) {
        margin-right: 0;
      }
      .boxContent:hover img {
        transform: scale(1.1);
      }
      img {
        width: 324px;
        height: 252px;
        transition: all 0.8s;
        object-fit: cover;
        border: none;
        max-width: 100%;
        display: inline-block;
      }
      .textContent {
        padding: 15px;
        // border: 1px solid #dddddd;
        border-top: none;
        .textContent1 {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333333;
        }
        .textContent2 {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .pageContent {
      margin: 20px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      /deep/ .el-pager li:not(.disabled).active {
        background-color: #17a763;
      }
    }
  }
}
</style>